import { apiBaseUrl } from "../constants/constants";
export const apiUrl = `${apiBaseUrl.devServer}/api`;

//optional layer to add different environments of server
export const apiUrlPrefix = `${apiUrl}/v1`;
export const apiUrlPrefix2 = `${apiUrl}/v2`;
export const apiUrlPrefix3 = `${apiUrl}/v3`;

//main objects for different api urls
export const apiConstant = {
    testApi: apiUrl + "/getvimeo",
    session: {
        checkSessionValidity: apiUrlPrefix + "/check_session"
    },
    user: {
        signup: apiUrlPrefix + "/sign_up",
        checkEmail: apiUrlPrefix + "/check_email",
        signin: apiUrlPrefix + "/sign_in",
        forgotPassword: apiUrlPrefix + "/forgot_password_mail",
        checkVerificationToken: apiUrlPrefix + "/check_token_validity",
        verifyUserMail: apiUrlPrefix + "/verify_token",
        resestPassword: apiUrlPrefix + "/reset_password",
        resendLink: apiUrlPrefix + "/resend_link",
        deleteUser: apiUrlPrefix2 + "/users", 
        verifyAppleUser: apiUrlPrefix2 + "/users/verify_user",
        appleSign: apiUrlPrefix2 + "/users",
        memberInvitation: apiUrlPrefix2 + "/users/member_invitation",
        userRole: apiUrlPrefix2 + "/users/user_role",
        approveUser: apiUrlPrefix + "/approve_user",
        checkSession: apiUrlPrefix + "/check_session"
    },

    event: {
        create : apiUrlPrefix2 + "/events",
        cancelEvent: apiUrlPrefix + "/events/cancel",
        uploadVideo: apiUrlPrefix + "/upload",
        uploadList: apiUrlPrefix + "/media_files",
        addEvent: apiUrlPrefix2 + "/events/add_collab_event",
        downloadMedia: apiUrlPrefix + "/media_files/download",
        favoriteMedia: apiUrlPrefix + "/media_files/favourite",  
        guestEvent: apiUrlPrefix2 + "/events/guest_detail",
        acceptFinalVideo: apiUrlPrefix2 + "/events/accept_final_video",
        uploadListToLinkEvent: apiUrlPrefix + "/media_files/event_files",
        importFiles: apiUrlPrefix + "/media_files/import_file", 
        allMediaFiles: apiUrlPrefix + "/media_files/show_all",
        markViewed: apiUrlPrefix + "/media_files/view",
        createZippedMedia: apiUrlPrefix + "/media_files/create_zipped_media",
        downloadZippedMedia: apiUrlPrefix + "/media_files/show_zipped_media",
        viewProjectMember: apiUrlPrefix2 + "/events/view_member",
        addProjectMember: apiUrlPrefix2 + "/events/add_member",
        updateProjectMemberRole: apiUrlPrefix2 + "/events/update_role",
        changeProjectAdmin: apiUrlPrefix2 + "/events/change_admin",
        removeProjectAdmin: apiUrlPrefix2 + "/events/remove_admin",
        removeProjectMember: apiUrlPrefix2 + "/events/remove_member",
        myMediaFileProjects: apiUrlPrefix2 + "/events/zip_event_list",
        myMediaFilesOfProject: apiUrlPrefix + "/media_files/show_downloadable",
        editNotes:  apiUrlPrefix2 + "/events/edit_description",
        editSongText:  apiUrlPrefix2 + "/events/edit_song_text",
        watchVideoLink: apiUrlPrefix2 + "/events/final_video_link"
    },

    options: {
        genre: apiUrlPrefix + "/options/genre",
        tone: apiUrlPrefix + "/options/tone",
        phoneCount: apiUrlPrefix + "/options/phone_count",
        qualityOption: apiUrlPrefix + "/options/quality_option",
        eventType: apiUrlPrefix + "/options/event_type",
        videoLength: apiUrlPrefix + "/options/video_length",
        statusType: apiUrlPrefix + "/options/status_type",
        role:  apiUrlPrefix + "/options/role",
        asset:  apiUrlPrefix + "/options/label",
    },

    consents:{
        consentDetail: apiUrlPrefix2 + "/consents/upload_description",
        eventVideoUploadConsent: apiUrlPrefix2 + "/consents/event_file_upload"
    },

    revision:{
        videoUrl: apiUrlPrefix2 + "/revisions/final_video_url",
        checkNewVideo: apiUrlPrefix2 + "/revisions/check_new_final_video",
        createRevision: apiUrlPrefix2 + "/revisions",
        getRevisionLogs: apiUrlPrefix2 + "/logs",
        deleteLogs: apiUrlPrefix2 +  "/logs",
       // createRevisionLogs: apiUrlPrefix2 + "/revisions/create_log",
        submitRevision: apiUrlPrefix2 + "/revisions/submit_revision",
        disableRevisionTutorial: apiUrlPrefix2 + "/revisions/revision_tutorial_flag"
    },

    organisation:{
        viewOrganisationMember: apiUrlPrefix3 + "/organisations/view_member",
        addOrganisationMember: apiUrlPrefix3 + "/organisations/add_member",
        updateOrganisationMemberRole: apiUrlPrefix3 + "/organisations/update_role",
        changeAdmin: apiUrlPrefix3 + "/organisations/change_admin",
        removeAdmin: apiUrlPrefix3 + "/organisations/remove_admin",
        removeOrganisationMember: apiUrlPrefix3 + "/organisations/remove_member",
        updateOrganisationName: apiUrlPrefix3 + "/organisations/update_name",
        butterflyAccess: apiUrlPrefix3 + "/organisations/member_butterfly_access"
    },

    team:{
        createTeam: apiUrlPrefix3 + "/teams",
        updateTeamPrivacy: apiUrlPrefix3 + "/teams/update_scope",
        renameTeam: apiUrlPrefix3 + "/teams/rename",
        removeTeam: apiUrlPrefix3 + "/teams/delete",
        addTeamMember: apiUrlPrefix3 + "/teams/add_member",
        viewTeamMembers: apiUrlPrefix3 + "/teams/view_member",
        updateTeamMemberRole: apiUrlPrefix3 + "/teams/update_role",
        changeTeamAdmin: apiUrlPrefix3 + "/teams/change_admin",
        removeTeamAdmin: apiUrlPrefix3 + "/teams/remove_admin",
        removeTeamMember: apiUrlPrefix3 + "/teams/remove_member",
    },
    permission:{
        getPermission: apiUrlPrefix3 + "/permissions"
    },
    roles:{
        role: apiUrlPrefix3 + "/roles",
    },
    themes:{
        theme: apiUrlPrefix3 + "/themes",
        removeTheme: apiUrlPrefix3 + "/themes/set_deafult"
    },
    assets:{
        assetFile: apiUrlPrefix3 + "/assets",   
    },
    eventNotes:{
        manageEventNotes: apiUrlPrefix3 + "/event_notes"
    },
    eventSongs:{
        manageEventSongs: apiUrlPrefix3 + "/event_songs"
    },
    guest:{
        registerGuestReviewer: apiUrlPrefix3 + "/guest_reviewers",
        eventDetails: apiUrlPrefix3 + "/guest_reviewers/event_detail",
        createLog: apiUrlPrefix2 + "/logs/create_guest_log"
    },



};