import { Avatar, Box, IconButton, TextField, Typography, getContrastRatio } from '@mui/material';
import React, { useRef } from 'react'
import CustomTooltip from '../../../components/CustomTooltip';
import CustomInput from '../../eventcreation/components/CustomInput';
import CustomAutocomplete from '../../eventcreation/components/CustomAutoComplete';
import { useState } from 'react';
import useValidator from '../../../hooks/useValidator';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CheckIcon from "../../../components/icons/CheckIcon";
import { getErrorMsg } from "../../../utils/validator";
import { afterValidate, generateLighterContrastColor, getInitials, isEventPending, setInput, timeStampToDateString, timeStampToDateTime, timeStampToDayString } from "../../../utils/commonServices";
import { resetCreationState } from '../../../redux/eventCreationSlice';
import { updateState } from '../../../redux/commonSlice';
import useEventTypeList from '../../eventcreation/hooks/useEventTypeList';
import useEventList from '../../dashboard/hooks/useEventList';
import useEventRequest from '../../../hooks/useEventRequest';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { updateTeamState } from '../../../redux/teamSlice';
import CustomMultiSelectAutocomplete from './CustomMultiSelectAutoComplete';
import { createTeam } from '../actions';
// import { customTheme as theme } from "../../../theme/customTheme";
import CustomButton from "../../../components/CustomButton";
import { getNamesFromIds, getRoleNamesFromIds } from '../../../commonactions/actions';
import { LoadingButton } from '@mui/lab';
import BoldCloseIcon from '../../../components/icons/BoldCloseIcon';
import PublicLockIcon from '../icons/PublicLockIcon';
// import PrivateLockIcon from '../icons/PrivateLockIcon';
import PrivateLockIcon from '../../../assets/PrivateLockIcon.svg'
import useCustomTheme from '../../../theme/useCustomTheme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import PublicLockIcon from '../../../assets/PublicLockIcon.svg'

const CreateTeam = (props) => {

  const {submitRequest, requestStatus} = props;
  const createEventReducer = useSelector((state) => state.createEventReducer);
  const teamsReducer = useSelector((state) => state.root.teamsReducer);
  const [pageData, setPageData] = useState(teamsReducer.pageData)
  const initialMemberData = {
    user_id:'',
    role_id:''
  }
  const [memberData, setMemberData] = useState(initialMemberData)
  const [checked, setChecked] = useState(false)
  const [enableEvents, setEnableEvents] = useState(false);
  const [refetchEvents] = useEventList(enableEvents);
  const [nameTooltip, setNametooltip] = useState(false)

const [pageIndex, setPageIndex] = useState(0);
const commonReducer = useSelector((state) => state.root.commonReducer);
const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
const navigate = useNavigate();
const dispatch = useDispatch()


const [nameValidationFlag, setNameValidationFlag] = useState(false);
const [privacyStatusValidationFlag, setPrivacyStatusValidationFlag] = useState(false)
const [teamMembersValidationFlag, setTeamMembersValidationFlag] = useState(false)
const [teamMemberValidationFlag, setTeamMemberValidationFlag] = useState(false)
const [teamMemberRoleValidationFlag, setTeamMemberRoleValidationFlag] = useState(false)
const [teamMembers, setTeamMembers] = useState([])
 
 const [videoLengthValidationFlag, setVideoLengthValidationFlag] = useState(false)
 const [eventType, selectedEventType] = useEventTypeList()
 const [eventTypeValidationFlag, setEventTypeValidationFlag] = useState(false)
 const colors = ['red', 'pink', 'black'];
 const nameAutoCompleteRef = useRef();
 const roleTypeAutoCompleteRef = useRef();
 const [theme ] = useCustomTheme()

 const isDisabled = memberData.role_id === '' || memberData.user_id === ''


const [members, setMembers] = useState(teamsReducer?.organization_members)



  const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(requestCallback);  
     // setPageData(initialData)   
  }

  const requestCallback= async ()=>{
 
    // console.log({pageData})
    // console.log({memberData})
    const payload = {
      name: pageData?.name,
      public: pageData?.public,
      members : JSON.stringify(pageData?.members)
    }
    createTeam(payload, dispatch, navigate)
    // dispatch(updateTeamState({all_teams : [...teamsReducer.all_teams, pageData], open_create_team_form:false}))

  }

  const validateInputs = (flag) => {
      setNameValidationFlag(flag)
      setPrivacyStatusValidationFlag(flag)
      if(teamMembers?.length === 0){
        setTeamMemberValidationFlag(flag)
        setTeamMemberRoleValidationFlag(flag)
      }
      // setTeamMembersValidationFlag(flag)
  }

  const addMemberClickHandler = () => {
    setTeamMembers((last)=>{
      return [...last, memberData]
    })
    setPageData((last) => {
      return { ...last, members: [...teamMembers, memberData] }
    })
   
    setTimeout(()=> {
      setMemberData(initialMemberData)
      setTeamMemberRoleValidationFlag(false)
      setTeamMemberValidationFlag(false)
    }, 500)

  }

  const setMemberDataToInitialValue =()=>{

  }



  
    const selectPublic = () => {
      setPageData((last) => {
          return { ...last, public: true }
      })
  }
  
  const selectPrivate = () => {
      setPageData((last) => {
          return { ...last, public: false }
      })
  }

  const handleChange = (event)=>{
    setChecked(event.target.checked)
  }




  const handleAutocompleteChange = (event, value) => {
    if(value){
        // console.log(value)
      //   setPageData((last) => {
      //     return { ...last, team_members: value }
      // })
      setMemberData((last) => {
        return { ...last, role_id: value.id }
    })
     
        // dispatch(updateState({open_previous_project_media_popup : true, imported_project_id: value.id, }))
        // dispatch(setAllMediaFilesArray({open_previous_project_media_popup : true, imported_project_id: value.id, }))
    }
  };

  const removeMemberClickHandler =(member)=>{
    const selectedMem = [...pageData.members]
    const updatedTeammembers = selectedMem.filter(item => item.user_id !== member.user_id)
    setTeamMembers(updatedTeammembers)
    setPageData((last) => {
      return { ...last, members: updatedTeammembers }
    })
  }

  const showTeamMembersToSelect =()=>{
    const filteredMembers = members.filter(member =>
      teamsReducer.pageData?.members?.filter(selectedMember => selectedMember.user_id === member.id)
    );
    return filteredMembers
  }
  return <>
 
  <Box sx={{display:'flex', flexDirection:'column', width:'100%', height:'100%', }}>
       {/* /////////////////////////////////////////////////////////////////////////////////////////////////// */}


  <Box sx={{width:'100%', marginBottom:{xs:'7px',sm:'12px',md:'15px',lg:'20px',xl:'33px'}}} textAlign='left'>

          <CustomTooltip arrow  PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -8],
                },
              },
            ],
          }}  
           open={nameTooltip} onClose={()=>setNametooltip(false)} disableHoverListener title={createEventReducer.duplicate == true ? 'Please enter new project name': ''} >
              <CustomInput
              onFocus={() =>{ setNameValidationFlag(false) }}
              onBlur={() => setNameValidationFlag(true)}
              autoFocus={teamsReducer.open_create_team_form}
              required
              errorFlag={
                nameValidationFlag &&
                  getErrorMsg("team_name", pageData?.name) != ""
                }
              errorMsg={getErrorMsg("team_name", pageData?.name)}
              fullWidth
              //   label="Event Name*"
              type="text"
              max={40}
              placeholder="Team Name (Max Character 40)"
              onChange={(event) =>
                {
                setNametooltip(false)
                setInput(
                  pageData?.name != ""
                  ? event.target.value.replace(/[<>:"/\\|?*]/g, '')
                  : event.target.value.trim(),
                  "name",
                  pageData,
                  setPageData
                )}
              }
              value={pageData?.name}
              />
          </CustomTooltip>
          <Typography sx={{fontFamily: 'Rubik',fontSize:{xs:'6px',md:'7px',lg:'8px',xl:'11px'}, paddingLeft:'15px', color:'#08231B'}}><span style={{fontWeight:600, color:'#08231B'}}>Note:</span> You can change the name of your team at anytime</Typography>
     </Box>


  {/*/////////////////////////////////////////////privacy setting///////////////////////////////////////////////// */}

      <Box sx={{width:'100%', display:'flex', flexDirection:'column', marginBottom:{xs:'10px',md:'15px',lg:'20px',xl:'42px'}}} textAlign='left'>
        <Typography sx={{marginBottom:{xs:'11px',lg:'15px',xl:'18px'}, fontFamily: 'Rubik', fontWeight:600, fontSize:{xs:'8px',lg:'11px',xl:'13px'}, color:'#08231B'}}>Privacy Status</Typography>
        <Box sx={{display:'flex'}} gap={4}>
            <Box flex={1} sx={{display:'flex'}} gap={5}>
         
                <Box sx={{border:pageData.public === true? `3px solid ${theme.palette.primary.main}`:'1px solid black', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center', height:{xs:'70px',md:'70px',lg:'83.33px',xl:'125px'}, width:{xs:'80px',sm:'100px',md:'120px',lg:'137px',xl:'205px'}, cursor:'pointer', backgroundColor: pageData.public === true ? `${theme.palette.primary.main}60` : 'white !important' , position:'relative'}} flex={1} p={1} id='landscape' onClick={selectPublic}>
                          <Box flexGrow={1} sx={{display:'grid', placeItems:'center',position:'absolute'}}>
                            {/* <img src={PublicLockIcon} alt="private" height='60%' style={{zIndex: 14}}/> */}
                                
                            <Box sx={{width:{xs:'20px',md:'25px',lg:'31px',xl:'45px'}, height:{xs:'10.5px',md:'13px',lg:'16px',xl:'23px'}, borderLeft:{xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`}, borderRadius:{xs:'3px 3px 0px 0px', sm:'5px 5px 0px 0px',lg:'7px 7px 0px 0px', xl:'10px 10px 0px 0px'}, display:'flex', borderTop: {xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`},  }}>
                            </Box>
                            <Box sx={{width:{xs:'35px',md:'40px',lg:'46px',xl:'69.19px'},  border:{xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`}, height:{xs:'20px',md:'25px',lg:'31px',xl:'50px'}, display:'flex',  borderRadius:{xs:'3px', sm:'5px',lg:'7px', xl:'10px'},}}>
                              <Box flexGrow={1} sx={{display:'grid', placeItems:'center'}}>
                                
                              </Box>
                            </Box>
                            
                              {/* <PublicLockIcon sx={{fontSize:'100px'}}/> */}
                          </Box>
                          
                          <Box flexGrow={1} sx={{display:'grid', placeItems:'center'}}>
                            <Typography style={{fontFamily: 'Rubik', color: pageData.public ===true? 'white' : 'black'}}  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'}, fontWeight:600,zIndex:15, position:'absolute'}}>Public</Typography>
                          </Box>
                </Box>
           
                
  
        
        
                <Box sx={{border:pageData.public === false? `3px solid ${theme.palette.primary.main}`:'1px solid black', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center',height:{xs:'70px',md:'70px',lg:'83.33px',xl:'125px'}, cursor:'pointer', backgroundColor: pageData.public === false? `${theme.palette.primary.main}70`:'white !important', position:'relative'}} flex={1} p={1} id='portrait' onClick={selectPrivate}>     
                         
                          <Box flexGrow={1} sx={{display:'grid', placeItems:'center',position:'absolute'}}>
                            {/* <img src={PrivateLockIcon} alt="private" height='59%' style={{zIndex: 14}}/> */}

                            <Box sx={{width:{xs:'20px',md:'25px',lg:'31px',xl:'45px'}, height:{xs:'10.5px',md:'13px',lg:'16px',xl:'23px'}, borderLeft:{xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`}, borderRadius:{xs:'3px 3px 0px 0px', sm:'5px 5px 0px 0px',lg:'7px 7px 0px 0px', xl:'10px 10px 0px 0px'}, display:'flex', borderTop: {xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`}, borderRight: {xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`},}}>
                            </Box>
                            <Box sx={{width:{xs:'35px',md:'40px',lg:'46px',xl:'69.19px'},  border:{xs:`2px solid  ${theme.palette.primary.main}60`,sm:`2.5px solid   ${theme.palette.primary.main}60`,lg:`3.5px solid   ${theme.palette.primary.main}60`,xl:`5px solid  ${theme.palette.primary.main}60`}, height:{xs:'20px',md:'25px',lg:'31px',xl:'50px'}, display:'flex',  borderRadius:{xs:'3px', sm:'5px',lg:'7px', xl:'10px'},}}>
                              <Box flexGrow={1} sx={{display:'grid', placeItems:'center'}}>
                                
                              </Box>
                            </Box>
                            {/* <PrivateLockIcon/> */}
                          </Box>
                          <Box flexGrow={1} sx={{display:'grid', placeItems:'center',}}>
                            <Typography style={{fontFamily: 'Rubik', color: pageData.public === false ? 'white' : 'black'}} sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'}, fontWeight:600,position:'absolute' , zIndex: 15}}>Private</Typography>
                          </Box>
                      
                </Box>
            </Box>
            <Box flex={0.8}  sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',pl:{xs:2, sm:1,md: 3, lg: 4}}}>
                <Typography ml={2}  sx={{fontFamily: 'Rubik', fontSize:{xs:'11px', md:'12px',lg:'13.34px', xl:'20px'}, color:'#08231B'}}> When set to private only members of your team will have access to this team's projects.</Typography>
             
            </Box>
            
        </Box>
      </Box>

{/* ////////////////////////////////////////////////////////add members/////////////////////////////////////////// */}

    {/* <Box sx={{width:'100%', display:'flex', marginBottom:{xs:'25px',sm:'25px',md:'25px',lg:'25px',xl:'33px'}}} gap={4}>
        <Box flex={1} >

                    <CustomMultiSelectAutocomplete
                        id="team_members"
                        options={teamsReducer.organization_members}
                        onFocus={() => {
                          setNameValidationFlag(true)
                          setTeamMembersValidationFlag(false)
                          }}
                        onBlur={() => setTeamMembersValidationFlag(true)}
                        required
                       
                        errorFlag= {teamMembersValidationFlag && getErrorMsg("team_members", pageData?.team_members) != []}
                        errorMsg= {getErrorMsg("team_members", pageData?.team_members)}
                        onChange={handleAutocompleteChange}
                        label=""
                        placeholder='Select a member to join this team...'
                        getOptionLabel={(option) =>
                            Object.keys(option).length > 0 ? option.value : ""
                        }
                        multiple
                        value={pageData.team_members}
                    />

                     

       
      </Box>

    </Box> */}
    <Box mt={2} sx={{width:'100%', display:'flex', marginBottom:{xs:'25px',sm:'25px',md:'25px',lg:'25px',xl:'33px'}}} gap={4}>
                            <Box style={{width:'40%'}}>

                  
                              <CustomAutocomplete
                                  onFocus={()=>{
                                    setNameValidationFlag(true)
                                    setPrivacyStatusValidationFlag(true)
                                    setTeamMemberValidationFlag(false)
                                  }}
                                  onBlur={()=> teamMembers?.length === 0 &&  setTeamMemberValidationFlag(true)}
                                  options={members.filter(member => !teamMembers.some(selected => selected.user_id === member.id))}
                                  placeholder='Select...'
                                  required
                                  errorFlag={
                                  teamMemberValidationFlag &&
                                  getErrorMsg("member", memberData?.user_id) != ""
                                  }
                                  errorMsg={getErrorMsg("member", memberData?.user_id)}
                                  onChange={(event, value)=>{
                                    // setPageData((last) => {
                                    //     return { ...last, user_id: value.id };
                                    // });
                                    setMemberData((last) => {
                                        return { ...last, user_id: value.id };
                                    });
                                  }}
                                  value={getNamesFromIds(memberData?.user_id, members)}
                                  getOptionLabel={(option) =>
                                  Object.keys(option).length > 0 ? option.name : ""
                                  }
                                  
                                  renderOption={(props, option, { selected , index}) => (
                                    <li {...props} >       
                                        <Avatar
                                          sx={{
                                          borderRadius: '5px',
                                          backgroundColor: colors[index % colors.length],
                                          color: 'white',
                                          width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                          height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                          paddingRight: 0,
                                          marginRight: 0,
                                          fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                          textTransform: 'uppercase',
                                          fontFamily: 'Rubik',
                                          fontWeight:600,
                                          mr:2
                                          }}
                                        >
                                          {getInitials(option.name)}
                                        </Avatar>
                                        <Box sx={{'& span': {color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',},}}>{option.name}</Box>
                                    </li>
                                )}
                              />
             
                            </Box>
                            <Box sx={{width:'45%', display:'flex', alignItems:'end', gap:1}}>
                            <Box style={{width:'92%'}}>         
                              <CustomAutocomplete
                                options={dropdownReducer?.team_roles}

                                placeholder='Set as...'
                                onFocus={() => {
                                    setNameValidationFlag(true)
                                    setPrivacyStatusValidationFlag(true)
                                    teamMembers?.length === 0 &&  setTeamMemberValidationFlag(true)
                                    setTeamMemberRoleValidationFlag(false)
                                }}
                                onBlur={() => teamMembers?.length === 0 &&   setTeamMemberRoleValidationFlag(true)}
                                required
                                errorFlag= {teamMemberRoleValidationFlag && getErrorMsg("role_type", memberData?.role_id) != ""}
                                errorMsg= {getErrorMsg("role_type", memberData?.role_id)}
                                onChange={handleAutocompleteChange}
                                value={getRoleNamesFromIds(memberData?.role_id, dropdownReducer?.team_roles)}
                                getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                                }
                                />
                            </Box>
                              <CustomTooltip title={ <Box sx={{display:'flex', flexDirection:'column', paddingX:{md:'1rem', lg:'1.2rem', xl:'2rem'},height:'100%'}}>
            <Box sx={{height: '100%'}} className= 'flex col j-center a-center' style={{alignItems:'flex-start'}}> 
            <Typography  sx={{fontSize:'12px', fontWeight:600, fontFamily:'Rubik', color:'#000'}}>Team Leads</Typography>
            <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0} >
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>Add or remove team members</Typography>
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Change team member roles</Typography>
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Create and manage projects</Typography>
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik',textAlign:'justify', color:'#000'}}>Have full access to all projects of their team</Typography>
              </Box>
              <Typography sx={{fontSize:'12px', fontWeight:600, textAlign:'justify', fontFamily:'Rubik', color:'#000'}}>Team Members</Typography>
              <Box component='ul' pl={1} style={{listStyleType: 'disc'}} mt={0}>
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of other team members</Typography>
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View list of projects they are associated with</Typography>
                <Typography component='li' sx={{fontSize:'10px', fontFamily:'Rubik', textAlign:'justify', color:'#000'}}>View their team name on the main dashboard</Typography>
              </Box>
            </Box>
          </Box>}>
                                <InfoOutlinedIcon sx={{cursor:'pointer',fontSize:{xs:'11px', md:'13px',lg:'14px', xl:'20px'}}}/>
                              </CustomTooltip>
                            </Box>
                            <Box style={{width:'20%'}}>
                              <CustomButton  disabled={isDisabled} btnText='Add' variant='contained' color='secondary' type='button' onClick={addMemberClickHandler}  style={{
                              // backgroundColor:theme.palette.secondary.main,
                               borderRadius:5, border:'none', fontSize:'12px', padding:'10px', width:'6.1rem', fontWeight:600, textTransform:'none', height:'20px', opacity: isDisabled && 0.5, fontFamily:'Rubik'}}/>
       
                            </Box>
                  </Box>

  {/* //////////////////////////////////members added///////////////////////////////////////// */}

    <Box flexGrow={1} sx={{width:'100%', display:'flex', flexDirection:'column',}} gap={1}  >           
 

            <Box sx={{width:'85%', overflowY:'scroll' }} >
                  {pageData.members?.map((member, index) =>(
                    <>
                        <Box key={member?.user_id} className='flex a-center j-between' mt={1}>
                              <Box  className='flex a-center' gap={2}>
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index % colors.length],
                                    color: 'white',
                                    width: { xs: '18px',  md: '20px', lg: '22px', xl: '32px' },
                                    height: { xs: '18px',  md: '20px', lg: '22px', xl: '32px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    {/* {getInitials(member.name)} */}
                                    {getInitials(getNamesFromIds(member?.user_id, members)?.name)}
                                </Avatar>
                                <Box textAlign={`left`} flex={3} className='flex col' sx={{fontSize:{xs:'10px',sm:'10px', md:'11px',lg:'12px', xl:'17px'}, fontWeight:600, color:'#000', fontFamily:'Rubik'}}>
                                  {/* {member.name} */}
                                  {getNamesFromIds(member?.user_id, members)?.name}
                                  <br />
                                  <Typography sx={{fontSize:{xs:'8px',lg:'9px',xl:'12px'}, fontWeight:400, fontFamily:'Rubik', color:'#000'}}> {getNamesFromIds(member?.user_id, members)?.email}</Typography>
                                </Box>
                              </Box>
                              <Box className='flex a-center' sx={{ width:'40%', justifyContent:'space-between'}}>
                                  <Typography sx={{ fontSize: {xs:'10px',sm:'10px', md:'11px',lg:'12px', xl:'17px'}, fontWeight: 500 , fontFamily:'Rubik'}}>
                                    {getRoleNamesFromIds(member?.role_id, dropdownReducer?.team_roles)?.name}
                                  </Typography>
               
                                  {/* <IconButton edge="end" style={{justifySelf:'right', }}  onClick={()=> removeMemberClickHandler(member)}>
                                    <ClearRoundedIcon  style={{ fontSize: '14px', fontWeight:800 }} />
                                  </IconButton> */}
                                  <BoldCloseIcon style={{justifySelf:'right', cursor:'pointer',  fontSize: '16px',}} onClick={()=> removeMemberClickHandler(member)} />
                              </Box>
                            </Box>
     
            </>
                  ))}
            </Box>

 
    </Box>

    {/* <Box  sx={{width:'90%', display:'flex', flexDirection:'column', justifyContent:'flex-end', backgroundColor:'red'}} gap={0.5} mt={2} mr={2}>

         <Typography textAlign={'left'} sx={{fontFamily: 'Rubik', fontSize:{xs:'9px', md:'10px',lg:'11.34px', xl:'18px'}, color:'#08231B'}}><span style={{fontWeight:600}}>Team Leads</span> Add or remove team members, Change team member roles, Create and manage projects, Have full access to all projects of their team.</Typography>
         <Typography textAlign={'left'} sx={{fontFamily: 'Rubik', fontSize:{xs:'9px', md:'10px',lg:'11.34px', xl:'18px'}, color:'#08231B'}}><span style={{fontWeight:600}}>Team Members</span> View list of other team members, View list of projects they are associated with, View their team name on the main dashboard.</Typography>
        
    </Box> */}
   

 

{/* /////////////////////////////////////create event Button//////////////////////////////////////////// */}
     

        <Box sx={{ display:'flex', justifyContent:'flex-end' }} >
       
          <Box >
      
        {commonReducer?.is_loading === true ? <LoadingButton
               // size="small"
                sx={{
                    '& .MuiLoadingButton-loadingIndicator': {
                        color: theme.palette.primary.main,
                    },
                    height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'70px'},
                   minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'70px !important'},
                    backgroundColor: '#08231B'
                }}
                loading={commonReducer?.is_loading === true}
                variant="outlined"
                disabled
                >
               
           </LoadingButton> :
           <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} onClick= {finishedHandler}  sx={{fontSize:{xs:'40px !important',sm:'44px !important',md:'50px !important', lg:'56px !important',xl:'72px !important'}, cursor: 'pointer',}}/>
           }
          </Box>
         

            {/* <CustomButton  
            style={{
                width: 100,
                borderWidth: 2,
                fontSize: 14,
                fontWeight: 600,
                boxShadow:  '0px 4px 10px rgba(0, 0, 0, 0.25)',
                borderRadius: 10}}
                // disabled={requestStatus === "loading" }
                 disabled={!checked}
                 onClick= {finishedHandler} 
                btnText = "FINISH"  
                variant="contained"
                /> */}
        </Box>

    
  


       {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}

    </Box>
</>
}

export default CreateTeam