import React from 'react'
import useCustomTheme from "../../theme/useCustomTheme";
import { makeStyles } from "@mui/styles";
import { Box } from '@mui/material';
import { updateState } from '../../redux/commonSlice';
import { PERMISSION } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import FilmingTutorialCard from '../events/components/FilmingTutorialCard';
import GuestUserInfoPopup from './components/GuestUserInfoPopup';
import GuestEventDetailCard from './components/GuestEventDetailCard';
import GuestRequestLog from './components/GuestRequestLog';
import PopupVideoPlayer from '../../components/PopupVideoPlayer';
import SubmitChangeRequestTutorialPopup from '../completedvideo/components/SubmitChangeRequestTutorialPopup';
import GuestVideoPlayer from './components/GuestVideoPlayer';
import GuestUserCard from './components/GuestUserCard';
import EventCancelledPage from './components/EventCancelledPage';

const GuestReviewPage = () => {
    const classes = useStyles();
    const [theme ] = useCustomTheme()
    const dispatch = useDispatch()
    const guestReducer = useSelector(state=> state.guestReducer)
    const commonReducer = useSelector(state=> state.root.commonReducer)
    const reviewEventReducer = useSelector((state) => state.reviewEventReducer);

 

  return (
    <>
    {guestReducer?.event_details?.cancel === true || !guestReducer?.show_guest_revision_page ? <>
        <EventCancelledPage/>
    </> : <>
    <Box container className={classes.outerContainer} sx={{ padding: '2.5% 3.9% 2.1% 2.2%'}} >
         
         <Box gap={2} className='flex row a-start j-end'>
                  <GuestUserCard/>
                </Box>
                <Box container className={classes.container}>
        <Box className={classes.leftContainer}>
              <Box sx={{height:{xs:'400px',sm:'400px',md:'58vh',lg:'62vh',xl:'70vh'}, marginBottom:{xs:'15px',md:'20px',lg:'23px',xl:'35px'}}}>
                 <GuestEventDetailCard eventData/>
              </Box>
              <Box  sx={{height:{xs:'105px',sm:'105px',md:'16.1vh',lg:'18.2vh',xl:'16.1vh'}}} >
                <FilmingTutorialCard onClick= {()=> dispatch(updateState({play_tutorial: true})) } clickable = {true} />
              </Box>
             
        </Box>
  
        <Box className={classes.rightContainer}>
          
              <Box style={{display:'flex', gap:'2.34%'}} sx={{ marginBottom:{xs:'15px',sm:'15px',md:'15px',lg:'20px',xl:'30px'},  marginTop:{xs:'8px', sm:'10px', md:'12px',lg:'14px',xl:'23px'}, width:'100%', }}>

                  {/* <Box sx={{height:{xs:'100px',sm:'100px', md:'120px',lg:'19.5vh',xl:'20vh'}, width:'26%'}} container  >
                     
                      <ProjectMembersCard
                      
                      />
                  </Box>
                  <Box sx={{height:{xs:'100px',sm:'100px', md:'120px',lg:'19.5vh',xl:'20vh'}, width:'74%'}} container> 
                              <SubmitChangeRequestCard/> 
                  </Box> */}
              </Box>
 
              <Box sx={{display:'flex',
              height:{xs:'80%',sm:'80%',md:'86%',lg:'92.5%',xl:'94%'},
                width:'100%', 
               backgroundColor:'#ffffffbf', 
              boxShadow:'0px 5px 15px #00000029', borderRadius:'10px', alignItems:'center' ,pt:3}} >
              
                    <Box sx={{height:'100%', display:"flex", alignItems: 'flex-start' , paddingTop:'10px', justifyContent:'center', overflowY:'auto',  width:'70%'}}>
                        <GuestVideoPlayer  src={ guestReducer?.event_details?.revision_video_url}/>
                    </Box>
                     <Box sx={{height:'100%', mr: 3, width:'30%',  maxHeight:'768px' }}>
                        <GuestRequestLog/>
                    </Box>
              </Box>
        
        </Box>  
        </Box> 

      {guestReducer.guest_user_details_popup && <GuestUserInfoPopup/>}
      
      { commonReducer.play_tutorial == true && <PopupVideoPlayer />}

      {reviewEventReducer.open_change_request_tutorial_popup == true && <SubmitChangeRequestTutorialPopup />}
    </Box >
    </>}
  
    </>
  )
}

export default GuestReviewPage


const useStyles = makeStyles((theme) => ({
    outerContainer:  {
       fontFamily: 'Rubik',
       height: '100%',
       minHeight: '100vh',

       background: `linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670, ${theme.palette.secondary.main}20 , ${theme.palette.secondary.main}50 80%,  ${theme.palette.primary.main}60 , ${theme.palette.primary.main}60 )`,

       display: 'flex',
       flexDirection: 'column',
       overflowY: 'scroll !important',
       scrollbarWidth: 'none',
       msOverflowStyle: 'none',
       '&::-webkit-scrollbar': {
         display : 'none'
       },
   },
     container: {
      display:'flex',
      width:'100%',
      minWidth:'860px',
       height:'85vh',
     // height:'100%',  
  },
    leftContainer: {
      width:'21.1%',
       display:'flex',
       flexDirection:'column',
       justifyContent: 'flex-start',
       marginBottom:'41px',  
      // paddingLeft:'2.24%',
       height:'100%',
     
 },
       rightContainer:{
      width:'77.2%',
      display:'flex',
      flexDirection:'column',
     // marginRight:'4.9%',
      marginBottom:'51px',
      marginLeft:'1.7%',
      height:'100%',
     
  },
    fileBox: {
        width: '100%',
        display: 'flex',
        paddingLeft: '1.6%'
    }
}));